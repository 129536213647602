import logo from './logo.svg';
import './App.css';
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import { useCriiptoVerify } from '@criipto/verify-react';
import { Cookies, useCookies } from 'react-cookie';

function Redirect() {
  const {result, loginWithRedirect} = useCriiptoVerify();
  const [cookies, setCookie] = useCookies(['callbackURI']);

  useEffect(() => { 
    if(result?.id_token) {
        window.location.href = cookies.callbackURI + '?code=' + result?.id_token;
    }
  }, [result?.id_token])

    return (
        <div className='container'>
          <button className='button' onClick={() => (window.location.href = cookies.callbackURI + '?code=' + result?.id_token)}>Klar</button>
        </div>
    )
}

export default Redirect;
