import logo from './logo.svg';
import './App.css';
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import { useCriiptoVerify } from '@criipto/verify-react';
import { useCookies } from 'react-cookie';
import { Routes, Route, useLocation } from 'react-router-dom';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Signature() {
  const {isLoading, isInitializing, loginWithRedirect, result} = useCriiptoVerify();
  const [cookies, setCookie] = useCookies(['callbackURI']);
  const query = useQuery();

  useEffect(() => {
    if (isLoading || isInitializing) {
      
    } else {
      loginWithRedirect();
    };

  }, [isLoading, isInitializing]);

  useEffect(() => {
    const callback = query.get("callback");
    if(callback) {
        setCookie('callbackURI', callback);
    }

  }, [])

    return (<React.Fragment>
      {result?.error ? (
        <p>
          An error occured: {result.error} ({result.error_description}). Please try again:
        </p>
      ) : null}
      <div className='container'>
          <p className='loading'>Laddar</p>
      </div>
    </React.Fragment>)
}

export default Signature;
